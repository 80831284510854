<template>
    <div class="lesson-video-container">
        <!-- <vue-plyr>
            <video ref="videoPlayer" @timeupdate="updateTime" @pause="onPause" :src="video_url" controls crossorigin playsinline autoplay>
                <source size="1080" :src="video_url" type="video/mp4" />
            </video>
        </vue-plyr> -->
        <DashVideo ref="videoPlayer" :src="video_url" :isPrivate="true" crossorigin />
        <div class="back-btn mb-4">
            <a @click="$router.go(-1)" >
                <span class="backIcon">
                    <span class="icon-arrow"></span>
                </span>
                Back
            </a>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import store from "@/store"
import api from '@/services/api'
import DashVideo from '../../components/DashVideo.vue'
export default {
    name:"LessonVideos",
    components: {
        DashVideo
    },
    data() {
        return {
            userId: store.getters.user.id,
            courseId: this.$route.params.course,
            curriculumId: this.$route.params.curriculum,
            lessonId: this.$route.params.lesson,
            video_url:null,
            currentTime: 0,
        };        
    },
    mounted(){
        let app = this;
        app.renderDetail();
    },
    methods: {
        async renderDetail() {
            let app = this;
            const response = await axios.post(api.getUrl('/request/video'), {userId:app.userId, courseId:app.courseId, lessonId:app.lessonId, curriculumId:app.curriculumId }, {
                // responseType: 'blob',
                // headers: {
                //     'Content-Type': 'application/json',
                //     'Range': 'bytes=0-999999'
                // },
            });
            //const blob = new Blob([response.data], { type: 'video/mp4' });
            //app.video_url = URL.createObjectURL(blob);
            app.video_url = response.data.url;
        },
        updateTime() {
            let app = this;
            // Access the video element using the $refs object
            const videoPlayer = app.$refs.videoPlayer;
            // Update the currentTime data property
            app.currentTime = app.secondsToHms(parseInt(videoPlayer.currentTime));
        },
        onPause() {
            let app = this;
            // The video has been paused, update the time in the database
            app.updateUserVideoPlayingStatus();
        },
        secondsToHms(d) {
            d = Number(d);
            let h = Math.floor(d / 3600);
            let m = Math.floor(d % 3600 / 60);
            let s = Math.floor(d % 3600 % 60);

            let hDisplay = h > 0 ? h +':': "00:";
            let mDisplay = m > 0 ? m + ':' : "00:";
            let sDisplay = s > 0 ? s : "00";
            return hDisplay + mDisplay + sDisplay; 
        },
        async updateUserVideoPlayingStatus() {
            let app = this;
            axios.post(api.getUrl('/request/video/timetracking'),{userId:app.userId, courseId:app.courseId, lessonId:app.lessonId, curriculumId:app.curriculumId,currentTime:app.currentTime }).then((response) => {
                console.log(JSON.stringify(response.data));
            });
        },
    },
    beforeDestroy() {
        let app = this;
        // The component is about to be destroyed, update the time in the database
        app.updateUserVideoPlayingStatus();
    },
    created() {
        let app = this;
        app.$watch(() => app.$route.query,(newQuery) => {
            app.courseId = newQuery.course;
            app.lessonId = newQuery.lesson;
            app.renderDetail();
        })
    },
}
</script>